// src/app/shared/validators/mobile-or-email.validator.ts
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function mobileOrEmailValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;

        // Mobile number pattern (10 digits)
        // const mobilePattern = /^[0-9]{10}$/;
        const mobilePattern = /^[6-9][0-9]{9}$/;

        // Email pattern (basic validation)
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (value && !(mobilePattern.test(value) || emailPattern.test(value))) {
            return { invalidMobileOrEmail: true };
        }

        return null;
    };
}
